import React from 'react';
import Fullpage from './Fullpage';
import '../../css/home.css';

class Home extends React.Component {
  render() {

    return (
      <>
        <Fullpage />
      </>
    )
  }
}

export default Home;